import React from 'react';
import { useParams } from 'react-router-dom';

const ImageView = () => {
  const { eventName, imageName } = useParams();

  // Construct the image path dynamically
  const imagePath = require(`../_data/gallery/${eventName}/${imageName}.jpg`);

  return (
    <div  className="bg-gradient-to-b from-sky-50 to-sky-100">
      <div className="container mx-auto px-4 py-8 ">
        <h2 className="text-3xl font-bold mb-8 text-center text-sky-900">{eventName} Image</h2>
        <div className="flex justify-center">
          <img
            src={imagePath}
            alt={`${eventName} Image`}
            className="max-w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default ImageView;
