import './App.css';
import Homepage from './homepage/Homepage';
import FullCourses from './course/Courses';
import EventImages from './gallery/Gallery';
import ImageView from './gallery/ImageView';
import EventCard from './homepage/EventCard';
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import CourseView from './course/CourseView';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/courses/:category" element={<FullCourses/>} />
        <Route path="/courses/:category/:courseNo" element={<CourseView/>}/>
        <Route path="/events/" element={<EventCard full={true}/>}/>
        <Route path="/events/:eventName" element={<EventImages />}/>
        <Route path="/events/:eventName/:imageName" element={<ImageView />}/>
        <Route path="/" element={<Homepage />} />
      </Routes>
    </Router>
  );
}

export default App;