import React from 'react';
import { Link } from 'react-router-dom';

const EventCard = ({ full }) => {
  // Dynamically import all JSON files from the '_data' directory
  const context = require.context('../_data', true, /\/[^/]+\/event\.json$/);

  // Extract event information from the imported JSON files
  const events = context.keys().map(key => {
    const eventData = context(key);
    const directoryName = key.split('/')[2]; // Extracting directory name
    return {
      name: eventData.name,
      date: eventData.date,
      venue: eventData.venue,
      slug: directoryName // Using directory name as slug
    };
  });

  // Function to parse date strings
  const parseDate = (dateString) => {
    const parts = dateString.split('-');
    // Assuming date format is "DD-MM-YYYY"
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  // Parse dates and sort events based on date (latest events first)
  events.sort((a, b) => new Date(parseDate(b.date)) - new Date(parseDate(a.date)));

  // Render events based on the 'full' parameter
  const renderedEvents = full ? events : events.slice(0, 2);

  return (
    <div id="events" className="bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-sky-900 via-teal-800 to-gray-300 text-white min-h-screen flex justify-center items-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Latest Events</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {renderedEvents.map((event, index) => (
            <div key={index} className="rounded-lg overflow-hidden bg-white shadow-md flex flex-col justify-between">
              <div className="px-6 py-8">
                <div className="font-bold text-xl mb-4 text-black">{event.name}</div>
                <p className="text-gray-700">Date: {event.date}</p>
                <p className="text-gray-700">Venue: {event.venue}</p>
              </div>
              <div className="px-6 py-4 bg-gray-100">
                <Link to={`/events/${event.slug}`} className="inline-block bg-sky-900 hover:bg-sky-700 text-white font-bold py-2 px-6 rounded-md transition duration-300 ease-in-out">
                  View Images
                </Link>
              </div>
            </div>
          ))}
        </div>
        {!full && (
          <div className="flex justify-center mt-8">
            <div className="rounded-lg overflow-hidden bg-sky-900 border border-gray-700 shadow-md text-white">
              <div className="px-6 py-4 text-center">
                <Link to="/events" className="text-white hover:text-gray-200 font-bold">
                  See All Events
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventCard;
