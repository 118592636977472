const courseList = [
  {
    "courseName": "Robotics",
    "description": "21st Century ROBOTICS Skill for Your KIDS",
    "category": "school",
    "modules": {
      "Introduction to Robotics": [
        "Overview of robotics and its applications",
        "Introduction to Arduino and Raspberry Pi",
        "Installing the necessary software (Arduino IDE, Raspbian, etc.)"
      ],
      "Introduction to Programming": [
        "Basic programming concepts",
        "Variables",
        "Loops",
        "Conditionals, etc."
      ],
      "Hands on": [
        "Writing and uploading a simple program to control an LED using Arduino"
      ],
      "Electronics and Circuit Design": [
        "Introduction to electronic components",
        "Resistors",
        "Capacitors",
        "Relays",
        "Microprocessor, etc",
        "Building basic circuits using breadboards",
        "Wiring and controlling multiple LEDs using Arduino",
        "Introduction to sensors (light, temperature, etc.)"
      ],
      "Robotics Projects - Hands On": [
        "Line-following robot",
        "Obstacle-avoiding robot",
        "Robot arm: automation",
        "Remote-controlled car",
        "Smart home"
      ]
    }
  },
  {
    "courseName": "Astronomy Club",
    "description": "Explore the wonders of the universe in this introductory astronomy course, from the motions of the planets to the structure of galaxies",
    "category": "school",
    "modules": {
      "Galaxy Explorers": "Explore the wonders of the universe in this introductory astronomy course, from the motions of the planets to the structure of galaxies",
      "Unit 1: Introduction to Astronomy": [
        "Overview of astronomy as a field of study",
        "Brief history of astronomy",
        "The scientific method in astronomy",
        "Tools and technology used in astronomy"
      ],
      "Unit 2: Observing the Sky": [
        "The celestial sphere and its coordinates",
        "The motion of the stars and planets",
        "Telescopes and their use",
        "Observing the Moon"
      ],
      "Unit 3: The Solar System": [
        "Formation of the solar system",
        "The planets and their characteristics",
        "Moons, asteroids, and comets",
        "Dwarf planets and the Kuiper Belt"
      ],
      "Unit 4: Stars and Galaxies": [
        "Characteristics of stars",
        "The Hertzsprung-Russell diagram",
        "Stellar evolution",
        "Types of galaxies and their characteristics"
      ],
      "Unit 5: The Universe": [
        "The Big Bang theory",
        "The expansion of the universe",
        "Dark matter and dark energy",
        "The search for extraterrestrial life"
      ],
      "Unit 6: Current Topics in Astronomy": [
        "The latest discoveries and research in astronomy",
        "Careers in astronomy"
      ]
    }
  },
  {
    "courseName": "Novice Programmers - Python Essentials for AI",
    "description": "Introduction to programming using Python with a focus on AI applications",
    "category": "school",
    "modules": {
      "Introduction to Programming": [
        "What is programming?",
        "Why Python programming language?",
        "Setting up Python environment",
        "Basic programming concepts"
      ],
      "Data Types": [
        "Numeric data types: Integer, Float, Complex numbers",
        "Strings",
        "Boolean data type",
        "Type conversion"
      ],
      "Operators": [
        "Arithmetic operators",
        "Comparison operators",
        "Logical operators",
        "Assignment operators"
      ],
      "Data Structures": [
        "Lists",
        "Tuples",
        "Dictionaries",
        "Sets"
      ],
      "File Handling": [
        "Reading and writing to files",
        "Opening and closing files",
        "Working with CSV files"
      ],
      "Project": [
        "Morse Code Translator: Create a program that can translate English text to Morse code. The user inputs the text, and the program outputs the corresponding Morse code.",
        "Password Generator: Create a password generator that can generate a random password based on the user's input, such as length and the inclusion of special characters or numbers.",
        "Todo List: The to-do list maker is a simple Python project that allows the user to create a list of tasks they need to complete. The project typically involves the use of basic Python concepts such as lists, loops, conditionals, and input/output statements."
      ]
    }
  },
  {
    "courseName": "Artificial Intelligence 101",
    "description": "Discover the power of Machines, an introductory AI course that will teach you the basics of machine learning, neural networks, and more!",
    "category": "school",
    "modules": {
      "Unit 1: Introduction to AI": [
        "Overview of AI and its importance",
        "Basic concepts and terminology (machine learning, neural networks, deep learning, etc.)",
        "History of AI and its future prospects"
      ],
      "Unit 2: Machine Learning": [
        "Introduction to supervised and unsupervised learning",
        "Types of machine learning algorithms",
        "Implementing machine learning algorithms in Python"
      ],
      "Unit 3: Neural Networks": [
        "Introduction to artificial neural networks (ANNs)",
        "Building a basic neural network in Python",
        "Understanding the different layers and activation functions in ANNs"
      ],
      "Unit 4: AI Applications": [
        "AI applications in different fields (medicine, finance, transportation, etc.)",
        "Ethics and societal impacts of AI"
      ],
      "Unit 5: Projects": [
        "Image recognition: The students will create an AI program that can recognize and classify different types of images, such as animals, cars, and buildings.",
        "Chatbot: Build a simple chatbot that can answer basic questions and have a conversation with users.",
        "Prediction models: Create an AI model that can predict outcomes, such as weather patterns or stock prices.",
        "Game AI: Build a simple game, such as tic-tac-toe, and program an AI player that can play against human players."
      ]
    }
  },
  {
    "courseName": "The Art of Problem-Solving: A Course in Mathematics and Physics",
    "description": "Enhance your problem-solving skills with this comprehensive course covering various mathematical and physics concepts.",
    "category": "school",
    "modules": {
      "Unit 1: Introduction to Problem-Solving Techniques": [
        "Introduction to problem-solving strategies and methods",
        "Practice problems in mathematics and physics",
        "Basic algebraic and geometric concepts"
      ],
      "Unit 2: Number Theory and Counting": [
        "Properties of integers and prime numbers",
        "Number theory and modular arithmetic",
        "Counting principles and combinatorics"
      ],
      "Unit 3: Mechanics and Kinematics": [
        "Kinematics and motion in one and two dimensions",
        "Newton's laws of motion and applications",
        "Work, energy, and power"
      ],
      "Advanced": {
        "Unit 4: Electricity and Magnetism": [
          "Electric charge, fields, and forces",
          "Conductors, capacitors, and circuits",
          "Magnetic fields and forces"
        ],
        "Unit 5: Waves and Optics": [
          "Waves and wave properties",
          "Sound and light waves",
          "Reflection, refraction, and diffraction"
        ],
        "Unit 6: Modern Physics": [
          "Special relativity and time dilation",
          "Quantum mechanics and the wave-particle duality",
          "Atomic and nuclear physics"
        ],
        "Unit 7: Review and Practice": "Review of problem-solving techniques and strategies" }
      }
    },
  {
    "courseName": "Vedic Maths",
    "description": "Unlock the power of ancient Vedic Mathematics with this course covering fundamental, intermediate, and advanced levels.",
    "category": "school",
    "modules": {
      "Introduction to Vedic Maths": [
        "Overview of Vedic Maths and its history",
        "Basic principles and concepts of Vedic Math"
      ],
      "FUNDAMENTAL LEVEL": [
        "Simple mental calculations using Vedic Maths techniques",
        "Criss-Cross Multiplication Method",
        "Computing the Square of Numbers",
        "Finding Cube Roots of Perfect Cubes",
      ],
      "INTERMEDIATE LEVEL": [
        "Base Multiplication Technique",
        "Base Squaring Method",
        "Digit Summation Method",
        "Magic Square Mathematics",
        "Date and Calendar Calculations",
        "Solving General Equations",
        "Solving Systems of Simultaneous Linear Equations"
      ],
      "ADVANCED LEVEL": [
        "Calculating Square Roots of Non-Perfect Squares",
        "Finding the Cube of Numbers",
        "Base Division Method",
        "Solving Division Problems"
      ]
    }
  },
  {
    "courseName": "Python for Data Science and Machine Learning",
    "description": "Unlock the potential of Python as a powerful tool for data science and machine learning with this comprehensive course. Designed for beginners, this course provides a solid foundation in Python programming while emphasizing its application in the data science and machine learning domains. From mastering Python's syntax to delving into essential libraries like NumPy and Pandas, you'll gain the skills needed to manipulate and analyze data, prepare datasets, and set the stage for advanced data science and machine learning endeavors.",
    "category": "college",
    "modules": {
      "Introduction to Python Programming": [
        "Introduction to Python and its Importance",
        "Setting Up the Development Environment",
        "Python Syntax and Conventions",
        "Variables and Data Types",
        "Basic Input and Output"
      ],
      "String Manipulation and Operators": [
        "Exploring Strings in Python",
        "String Operations and Methods",
        "Understanding and Using Python Operators",
        "Mathematical and Logical Operations",
        "Bitwise and Comparison Operators"
      ],
      "Data Structures and Collections": [
        "Lists: Creation, Indexing, and Slicing",
        "Tuples: Immutable Data Structures",
        "Sets: Unordered Collections of Unique Elements",
        "Dictionaries: Key-Value Pairs for Efficient Lookups",
        "Iterating Through Data Structures"
      ],
      "Conditional Statements and Loops": [
        "Introducing Conditional Statements (if, elif, else)",
        "Logical Expressions and Decision Making",
        "Looping Concepts: for and while Loops",
        "Loop Control: break and continue Statements",
        "Nested Loops and Loop Applications"
      ],
      "Functions and Modular Programming": [
        "Understanding Functions and Their Importance",
        "Defining and Calling Functions",
        "Function Parameters and Arguments",
        "Return Statements and Function Outputs",
        "Scope and Lifetime of Variables",
        "Lambda Functions for Concise Operations"
      ],
      "Introduction to Data Analysis Libraries": [
        "Overview of Data Analysis with Python",
        "Exploring NumPy: Arrays and Numerical Computing",
        "Introduction to Pandas: Series and DataFrames",
        "Loading Data into DataFrames",
        "Basic Data Exploration and Statistics"
      ],
      "Data Cleaning and Missing Value Handling": [
        "The Importance of Data Cleaning",
        "Identifying and Handling Duplicates",
        "Strategies for Handling Missing Values",
        "Drop vs. Fill: Making Informed Decisions",
        "Ensuring Data Integrity: Cleaning Techniques"
      ],
      "Feature Engineering": [
        "The Role of Feature Engineering in Data Science",
        "Dealing with Categorical Data",
        "Encoding Techniques: Label and One-Hot Encoding",
        "Handling Ordinal Data and Mapping",
        "Data Validation: Ensuring Quality and Consistency",
        "Removing Irrelevant or Redundant Features"
      ],
      "Machine Learning Primer": [
        "Introduction to Machine Learning Concepts",
        "Python's Role in Machine Learning",
        "Overview of Scikit-Learn Library",
        "Basics of Training and Evaluating Models",
        "Building a Simple Machine Learning Pipeline"
      ],
      "Final Project and Wrap-Up": [
        "Applying Skills to Real-World Scenarios",
        "Designing and Implementing a Data Manipulation Project",
        "Presenting Insights and Learnings",
        "Course Reflection and Next Steps"
      ]
    }
  },
  {
    "courseName": "Mastering Machine Learning and Artificial Intelligence",
    "description": "Dive into the realm of artificial intelligence and machine learning mastery with our comprehensive 'Mastering Machine Learning and AI' course. Uncover the intricate art of transforming data into actionable insights, harness the predictive power of advanced algorithms, and craft intelligent solutions to real-world challenges.",
    "category": "college",
    "modules": {
      "Data Preprocessing Essentials": [
        "Fundamentals of Data Preprocessing",
        "Strategies for Handling Missing Data",
        "Transforming Categorical Data: Encoding Methods",
        "Normalizing and Scaling Features",
        "Detecting and Managing Outliers"
      ],
      "Predictive Power with Regression": [
        "Unveiling the World of Regression Analysis",
        "The Simplicity of Simple Linear Regression",
        "Navigating Multiple Linear Regression",
        "Curves and Complexity: Polynomial Regression",
        "Regress with Support: Support Vector Regression",
        "Decision Trees for Regression Insights",
        "Harnessing Randomness: Random Forest Regression"
      ],
      "Classification Craftsmanship": [
        "The Art of Classification Unveiled",
        "Modeling Probabilities: Logistic Regression",
        "Neighbors and Labels: k-Nearest Neighbors (K-NN)",
        "Boundaries and Support: Support Vector Machines (SVM)",
        "Kernels and SVM Elevation: Kernel SVM",
        "Naive Yet Effective: Naive Bayes Classification",
        "Deciphering with Trees: Decision Tree Classification",
        "Wisdom of the Forest: Random Forest Classification"
      ],
      "Grouping and Segmentation: Clustering": [
        "The Power of Grouping and Clustering",
        "Center of Attention: K-Means Clustering",
        "Hierarchical Harmony: Hierarchical Clustering"
      ],
      "Discovering Patterns: Association Rule Learning": [
        "Unearthing Patterns with Association Rule Learning",
        "Apriori Algorithm: Unveiling Association Insights",
        "Eclat Algorithm: Beyond Apriori for Association"
      ],
      "Learning from Interaction: Reinforcement Learning": [
        "Learning through Interaction: Reinforcement Basics",
        "Decision Time: Upper Confidence Bound (UCB)",
        "Sampling Strategy: Thompson Sampling"
      ],
      "Words to Insights: Natural Language Processing (NLP)": [
        "Translating Words into Insights: NLP Intro",
        "Preparing Text: The Journey of Preprocessing",
        "Bag of Words: Transforming Text to Vectors",
        "Text Classifiers: Navigating NLP Challenges"
      ],
      "Deep Dive into Deep Learning": [
        "Delving into the Depths: Introduction to Deep Learning",
        "Neural Wonders: Artificial Neural Networks (ANN)",
        "Visual Insight: Convolutional Neural Networks (CNN)",
        "From Expertise to Adaptation: Transfer Learning"
      ],
      "Reducing Dimensions, Enhancing Insights": [
        "Embracing Simplicity: Introduction to Dimensionality Reduction",
        "Capturing Essence: Principal Component Analysis (PCA)",
        "Discriminating Dimensions: Linear Discriminant Analysis (LDA)",
        "Kernels and Complexity: Kernel PCA"
      ],
      "Selecting, Tuning, and Boosting Models": [
        "Navigating Choices: Model Selection with k-fold Cross Validation",
        "Fine-tuning Mastery: Parameter Optimization with Grid Search and Random Search",
        "Amplifying Performance: Embracing XGBoost and Gradient Boosting"
      ]
    }
  },
  {
    "courseName": "Backend Engineering Foundations: From Zero to Hero",
    "description": "This course is meticulously designed for absolute beginners with the ambition to forge a career in backend engineering. It lays down a robust foundation in backend development, encompassing critical areas such as database management, coding, debugging, testing, and deployment. Through engaging modules and practical exercises, students will gain firsthand experience with the indispensable tools and technologies utilized by backend engineers today.",
    "category": "college",
    "modules": {
        "Understanding Databases": {
            "Objectives": "Grasp the principles of database design and learn about different types of databases (SQL vs NoSQL).",
            "Topics": "Database design basics, SQL and NoSQL databases, designing a simple database exercise"
        },
        "Basics of SQL": {
            "Objectives": "Master basic SQL commands and data manipulation.",
            "Topics": "SQL introduction, basic commands, data querying and manipulation, practical SQL operations"
        },
        "Reading and Understanding Codebases (Prerequisite: Basic programming knowledge, preferably Python)": {
            "Objectives": "Develop skills to navigate and comprehend various codebases, learning techniques for reading and understanding code.",
            "Topics": "Common coding practices, navigating codebases, understanding code, exploring an open-source project"
        },
        "Debugging Techniques": {
            "Objectives": "Learn debugging setup and common practices for issue resolution.",
            "Topics": "Debugging introduction, setting up a debugger, common techniques, debugging practice"
        },
        "Writing Automation Unit Tests": {
            "Objectives": "Grasp the importance of automated testing and learn to write unit tests.",
            "Topics": "Unit testing basics, writing test cases, Test-Driven Development (TDD), unit test writing exercise"
        },
        "UI to Backend to DBMS Pipeline (Prerequisites: Basics of JavaScript, Python, SQL)": {
            "Objectives": "Understand the integration of user interfaces, backend services, and databases, comprehending the flow of a web application.",
            "Topics": "Full-stack development overview, connecting UI with backend, database connectivity, building a full-stack application"
        },
        "Version Control with Git and GitHub": {
            "Objectives": "Learn version control fundamentals and how to manage source code with Git and GitHub.",
            "Topics": "Version control basics, Git essentials, GitHub introduction, managing projects with Git and GitHub"
        },
        "CI/CD Pipeline": {
            "Objectives": "Understand Continuous Integration and Deployment (CI/CD) concepts and set up a basic CI/CD pipeline.",
            "Topics": "CI/CD introduction, continuous integration, continuous deployment, CI/CD setup exercise"
        },
        "Course Completion": {
            "Description": "Students will emerge with a thorough understanding of backend development fundamentals, equipped to design, develop, test, and deploy backend web application components.",
            "Final Assessment": "A project to develop a full-stack application, an oral examination to discuss the project, challenges, and solutions."
        }
    }
}

 
];

export default courseList;
