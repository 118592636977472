import Hero from "./Hero";
import CoursesOffered from "./CategoryCard";
// import Blogs from "../blog/Blogs";
import Footer from "./Footer";
import Info from "./Info";
import EventCard from "./EventCard";

const Homepage = () => {
  return (
    <div className="snap-y snap-proximity overflow-y-auto overflow-x-hidden h-screen w-full scroll-smooth">
      <Hero/>
      <Info/>
      <CoursesOffered/>
      <EventCard full={false}/>
      <Footer />
    </div>
  );
};

export default Homepage;
