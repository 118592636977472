import { useParams } from 'react-router-dom';
import courseList from '../_data/courses';

const CourseView = () => {
    const { courseNo } = useParams();
    const course = courseList[Number(courseNo)];

    return (
        <div className="container mx-auto px-4 py-8">
            <div className=" bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-sky-900 via-teal-800 to-gray-300
             shadow-xl rounded-lg overflow-hidden text-gray-100">
                <div className="px-6 py-4">
                    <h2 className="text-3xl font-semibold text-lime-300 mb-4">{course.courseName}</h2>
                    <p className="text-base text-gray-200 mb-6">{course.description}</p>
                    <div className="border-t border-gray-200">
                        <div className="py-4">
                            <h3 className="text-xl font-semibold text-lime-300 mb-2">Modules</h3>
                            <ul className="divide-y divide-gray-200">
                                {Object.entries(course.modules).map(([unit, content]) => (
                                    <li key={unit} className="py-2">
                                        <h4 className="text-lg font-semibold text-lime-300 mb-1">{unit}</h4>
                                        {renderContent(content)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const renderContent = (content) => {
    if (Array.isArray(content)) {
        return (
            <ul className="list-disc list-inside pl-4">
                {content.map((subModule, index) => (
                    <li key={index} className="text-gray-100">{subModule}</li>
                ))}
            </ul>
        );
    } else if (typeof content === 'object') {
        return (
            <ul className="list-disc list-inside pl-4">
                {Object.entries(content).map(([subUnit, subModule]) => (
                    <li key={subUnit} className="text-gray-100">
                        <strong>{subUnit}:</strong> {subModule}
                    </li>
                ))}
            </ul>
        );
    } else {
        return <p className="text-gray-100">{content}</p>;
    }
};

export default CourseView;
